import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Row } from 'primereact/row';
import './main.css';

const data = [
    {
        dataset: 'Radcure',
        institution: 'UHN',
        scans: '2552',
        manuscriptUse: 'Training/Tuning/Internal Testing',
        imaging: '1',
        clinical: '2',
        oar: '3',
        doi: '10.7937/tcia.2019.8dho2gls'
    },
    {
        dataset: 'HNSCC-3DCT-RT',
        institution: 'MIAMI',
        scans: '94',
        manuscriptUse: 'Generalizability Assessment',
        imaging: '1',
        clinical: '2',
        oar: '3',
        doi: '10.7937/K9/TCIA.2018.13upr2xf'
    },
    {
        dataset: 'Deepmind',
        institution: 'HMS+Multi-Site',
        scans: '35',
        manuscriptUse: 'Generalizability Assessment',
        imaging: '1',
        clinical: '',
        oar: '3',
        doi: '10.48550/arXiv.1809.04430'
    },
    {
        dataset: 'PDDCA',
        institution: 'HMS',
        scans: '48',
        manuscriptUse: 'Generalizability Assessment',
        imaging: '1',
        clinical: '',
        oar: '3',
        doi: '10.1002/mp.12197'
    },
    {
        dataset: 'Radiomics-HN1',
        institution: 'MAASTRO',
        scans: '137',
        manuscriptUse: 'Generalizability Assessment',
        imaging: '1',
        clinical: '2',
        oar: '3',
        doi: '10.7937/tcia.2019.8kap372n'
    },
    {
        dataset: 'STRUCTSEG19',
        institution: 'CAS',
        scans: '50',
        manuscriptUse: 'Generalizability Assessment',
        imaging: '1',
        clinical: '',
        oar: '3',
        doi: '10.1007/978-3-030-71827-5_5'
    },
    {
        dataset: 'Head-Neck-CT-Atlas',
        institution: 'MDACC',
        scans: '215',
        manuscriptUse: 'Generalizability Assessment',
        imaging: '1',
        clinical: '2',
        oar: '3',
        doi: '10.7937/K9/TCIA.2017.umz8dv6s'
    },
    {
        dataset: 'SegRap 2023',
        institution: 'UESTC',
        scans: '120',
        manuscriptUse: 'Generalizability Assessment',
        imaging: '1',
        clinical: '',
        oar: '3',
        doi: 'arXiv:2312.09576'
    },
    {
        dataset: 'HaN-Seg',
        institution: 'SLOVENIA',
        scans: '42',
        manuscriptUse: 'Generalizability Assessment',
        imaging: '1',
        clinical: '',
        oar: '3',
        doi: '10.1002/mp.16197'
    }
];
const getColor = value => {
    switch (value) {
        case '1':
            return '#70ba77'; /*green*/
        case '2':
            return '#b87ed9'; /*purple*/
        case '3':
            return '#d1d166'; /*yellow*/
        case '4':
            return '#0e9bc9'; /*blue*/
        default:
            return '#808080'; /*gray*/
    }
};

const renderColoredCell = (rowData, field) => {
    const value = rowData[field];
    const color = getColor(value);

    return (
        <div
            style={{
                backgroundColor: color,
                width: '60px',
                height: '100%',
                borderRadius: '4px',
                boxSizing: 'border-box',
                border: '1px solid #ddd',
                minHeight: '2em',
                minWidth: '2em',
                margin: 'auto'
            }}
        ></div>
    );
};
function DataTableComponent() {
    const tableStyle = {
        borderCollapse: 'collapse',
        border: '2px solid #ddd'
    };

    const cellStyle = {
        padding: '8px',
        boxSizing: 'content-box',
        wordWrap: 'break-word',
        overflowWrap: 'break-word',
        outline: '1px solid #ddd'
    };

    const headerGroup = (
        <>
            <Row>
                <Column style={cellStyle} header="Dataset" rowSpan={3} />
                <Column style={cellStyle} header="Institution" rowSpan={3} />
                <Column style={cellStyle} header="Scans" rowSpan={3} />
                <Column style={cellStyle} header="Use In Manuscript" rowSpan={3} />
            </Row>
            <Row>
                <Column style={cellStyle} header="Available Data" colSpan={3} />
                <Column style={cellStyle} header="DOI" rowSpan={3} />
            </Row>
            <Row>
                <Column style={cellStyle} header="Imaging" />
                <Column style={cellStyle} header="Clinical" />
                <Column style={cellStyle} header="OAR" />
            </Row>
        </>
    );

    return (
        <div>
            <DataTable
                value={data}
                style={tableStyle}
                headerColumnGroup={headerGroup}
                className="data-table text-base sm:text-sm md:text-sm"
                scrollable
                showGridlines
            >
                <Column field="dataset" header="Dataset" style={cellStyle} />
                <Column field="institution" header="Institution" style={cellStyle} />
                <Column field="scans" header="Scans" style={cellStyle} />
                <Column field="manuscriptUse" header="Use in Manuscript" style={cellStyle} />
                <Column body={rowData => renderColoredCell(rowData, 'imaging')} header="Imaging" style={cellStyle} />
                <Column body={rowData => renderColoredCell(rowData, 'clinical')} header="Clinical" style={cellStyle} />
                <Column body={rowData => renderColoredCell(rowData, 'oar')} header="OAR" style={cellStyle} />
                <Column
                    body={rowData => (
                        <a
                            href={`https://doi.org/${rowData.doi}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: '#1E90FF', textDecoration: 'underline' }}
                        >
                            {rowData.doi}
                        </a>
                    )}
                    header="DOI"
                    style={cellStyle}
                />
            </DataTable>
        </div>
    );
}

export default DataTableComponent;
