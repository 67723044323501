//Team info at the top of the page
export const team = [
    {
        name: 'Joseph Marsilla',
        role: 'PhD Student',
        link: 'https://www.linkedin.com/in/joseph-marsilla/'
    },
    {
        name: 'Mattea Welch',
        role: 'Staff Scientist',
        link: 'https://www.linkedin.com/in/mattea-welch/'
    },
    {
        name: 'Joshua Siraj',
        role: 'Research Assistant',
        link: 'https://www.linkedin.com/in/joshua-siraj'
    },
    {
        name: 'Clare McElcheran',
        role: 'Research Assistant',
        link: 'https://www.linkedin.com/in/clare-mcelcheran'
    },
    {
        name: 'Denis Tkatchuk',
        role: 'Software developer',
        link: 'https://www.linkedin.com/in/denis-tkachuk-1b2577113'
    },
    {
        name: 'Sejin Kim',
        role: 'PhD Student',
        link: 'https://www.linkedin.com/in/sejinkim3'
    },
    {
        name: 'Tirth Patel',
        role: 'Software Engineer',
        link: 'https://www.linkedin.com/in/tirupatel/'
    },
    {
        name: 'Jun Won Kim',
        role: 'Associate Professor',
        link: ''
    },
    {
        name: 'Tony Tadic',
        role: 'Associate Professor',
        link: 'https://radonc.utoronto.ca/faculty/tony-tadic'
    },
    {
        name: 'Scott Bratman',
        role: 'Associate Professor',
        link: 'https://medbio.utoronto.ca/faculty/bratman'
    },
    {
        name: 'Andrew Hope',
        role: 'Associate Professor',
        link: 'https://radonc.utoronto.ca/faculty/andrew-hope'
    },
    {
        name: 'Benjamin Haibe-Kains',
        role: 'Professor',
        link: 'https://medbio.utoronto.ca/faculty/haibe-kains'
    }
];

//overview section data
export const overviewData = [
    {
        title: 'Data Curation',
        content:
            'We curated nine datasets using custom scripts and tools, extracting data from 523 patients who met selection criteria, each with 19 delineated OARs.'
    },
    {
        title: 'Model Training',
        content:
            ' Eleven open-source 3D segmentation networks were trained using our largest single-institution dataset. These models were selected from a pool of 60 studies after excluding 29 due to unavailable code and 20 due to computational or architectural constraints. The best-performing model was fine-tuned, and a new deep-learning model was trained for quantitative comparison.'
    },
    {
        title: 'Performance Evaluation',
        content:
            'All 11 trained models were evaluated using metrics that combined volumetric overlap indices (Dice similarity coefficient) and boundary distance measures (95th percentile Hausdorff Distance).'
    },
    {
        title: 'Clinical Acceptability',
        content:
            'The top-performing transparent model was assessed by four expert radiation oncologists through a blinded clinical acceptability review on the Quannotate platform.'
    },
    {
        title: 'Generalizability Testing',
        content: 'The generalizability of the best model was evaluated across eight external datasets.'
    }
];

export const abstractData = [
    {
        title: 'Purpose',
        content:
            'Auto-segmentation of organs at risk (OAR) in cancer patients is essential for enhancing radiotherapy planning and reducing inter-observer variability. Deep learning auto-segmentation models have shown promise, but their lack of transparency and reproducibility hinders their generalizability and clinical acceptability, limiting their use in clinical settings.'
    },
    {
        title: 'Methods',
        content:
            'This study introduces SCARF (auto-Segmentation Clinical Acceptability & Reproducibility Framework), a comprehensive framework designed to benchmark open-source convolutional neural networks for auto-segmentation of 19 essential OARs in head and neck cancer (HNC). Reproducibility of methods is achieved through harmonized data curation, and model training and assessment steps. New models can be benchmarked against 11 pre-trained open-source deep learning models, while estimating clinical acceptability using a regularized logistic regression model.'
    },
    {
        title: 'Results',
        content:
            'The SCARF framework code base is openly-available for OAR auto-segmentation benchmarking. During development, expert assessment of the best performing model labelled 16/19 AI-generated OAR categories as clinically acceptable with minor revisions. Additionally, boundary distance metrics were found to be 2x more correlated to clinical acceptability ratings than volumetric overlap metrics. These metrics, when used to model clinical acceptability achieved an area under the receiver operating characteristic curve of 0.60 '
    },
    {
        title: 'Conclusions',
        content:
            ' SCARF facilitates benchmarking and expert assessment of AI-driven auto-segmentation tools, addressing the need for transparency and reproducibility in this domain. Through its emphasis on clinical acceptability, SCARF fosters the integration of AI models into clinical environments, specifically within radiation therapy, paving the way for randomised clinical trials to evaluate their real-world impact.'
    }
];
