import { Link } from 'react-scroll';
import DataTableComponent from './DataTable';
import { Image } from 'primereact/image';
import { team, overviewData, abstractData } from './content';

function App() {
    return (
        <div className="mt-20 md:mt-4 mx-40 lg:mx-20 md:mx-0 flex flex-col justify-center items-center">
            <div id="main" className="py-10 flex flex-col justify-center items-center">
                <div className="flex flex-col items-center">
                    <h1 className="font-bold text-9xl md:text-7xl text-gray-800 text-center mb-2">SCARF</h1>
                    <h2 className="font-bold text-4xl md:text-2xl text-gray-700 text-center">
                        auto-Segmentation Clinical Acceptability & Reproducibility Framework
                    </h2>
                </div>
                <div
                    id="authors"
                    className="flex flex-row sm:flex-col flex-wrap justify-center items-center gap-4 mt-10 mb-16 px-32 xl:px-10 md:px-2"
                >
                    {team.map(member => (
                        <a
                            className="border-2 border-gray-300 rounded-xl w-60 hover:scale-110 transform transition duration-300 ease-in-out hover:bg-gray-200"
                            href={member.link}
                            target="_blank"
                            rel="noreferrer"
                            key={member.name}
                        >
                            <div className="p-3 text-center ">
                                <h2 className="text-xl md:text-md font-semibold mb-2">{member.name}</h2>
                                <h3 className="text-md md:text-xs text-gray-600">{member.role}</h3>
                            </div>
                        </a>
                    ))}
                </div>

                <div id="paper-and-code" className="flex flex-row flex-wrap gap-6 justify-center items-center">
                    <a
                        className="flex items-center justify-center px-7 py-3 md:p-3 text-2xl md:text-lg font-bold text-black hover:text-black hover:scale-105 transition duration-150 hover:bg-gray-200 border-2 border-gray-300 rounded-full w-40"
                        href="https://www.medrxiv.org/content/10.1101/2022.01.15.22269276v2"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img src="/images/logos/paper.png" alt="repository" className="md:w-6 w-8 mr-2" />
                        Paper
                    </a>
                    <a
                        className="flex items-center justify-center px-7 py-3 md:p-3 text-2xl md:text-lg font-bold text-black hover:text-black hover:scale-105 transition duration-150 hover:bg-gray-200 border-2 border-gray-300 rounded-full w-40"
                        href="https://github.com/bhklab/SCARF"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img src="/images/logos/github.svg" alt="repository" className="md:w-6 w-8 mr-2" />
                        Code
                    </a>
                    <a
                        className="flex items-center justify-center px-7 py-3 md:p-3 text-2xl md:text-lg font-bold text-black hover:text-black hover:scale-105 transition duration-150 hover:bg-gray-200 border-2 border-gray-300 rounded-full w-40"
                        href="https://colab.research.google.com/drive/1YjbnqRCKdaTnEg3xdKyo2bzSRpMNoQ8I?usp=sharing"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img src="/images/logos/colab.png" alt="repository" className="md:w-6 w-8 mr-2" />
                        Collab
                    </a>

                    <Link
                        className="flex items-center justify-center px-7 py-3 md:p-3 text-2xl md:text-lg font-bold text-black hover:text-black hover:scale-105 transition duration-150 hover:bg-gray-200 border-2 border-gray-300 rounded-full w-40 cursor-pointer"
                        to="data"
                        smooth={true}
                        duration={1000}
                        offset={-75}
                    >
                        <img src="/images/logos/data.png" alt="repository" className="w-6 md:w-8 mr-2" />
                        Data
                    </Link>
                </div>
                <div id="paper-and-code" className="flex flex-row flex-wrap gap-6 justify-center items-center mt-6">
                    <a
                        className="flex items-center justify-center px-7 py-3 md:p-3 text-2xl md:text-lg font-bold text-black hover:text-black hover:scale-105 transition duration-150 hover:bg-gray-200 border-2 border-gray-300 rounded-full w-54"
                        href="https://github.com/bhklab/med-imagetools"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img src="/images/logos/application.png" alt="repository" className="w-6 md:w-8 mr-2" />
                        Med-ImageTools
                    </a>
                    <a
                        className="flex items-center justify-center px-7 py-3 md:p-3 text-2xl md:text-lg font-bold text-black hover:text-black hover:scale-105 transition duration-150 hover:bg-gray-200 border-2 border-gray-300 rounded-full w-54"
                        href="https://github.com/bhklab/QUANNOTATE"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img src="/images/logos/development.png" alt="repository" className="w-6 md:w-8 mr-2" />
                        QUANNOTATE
                    </a>
                </div>
            </div>

            <div id="overview" className="px-32 lg:px-16 md:px-6 py-10">
                <div className="flex flex-col">
                    <h1 className="font-bold text-5xl md:text-3xl text-gray-800 mb-8">Overview</h1>
                    {/* <p className="md:text-left text-center text-gray-700 text-lg md:text-sm">
                        In this study, 582 patients meeting selection criteria with 19 delineated OARs underwent data extraction for
                        training 11 open-source 3D segmentation networks. These networks were chosen from a subset of 60 studies, excluding
                        29 due to code unavailability and 20 for computational or architectural constraints. Performance ranking on a test
                        set of 59 patients across all OARs determined the top model, subsequently fine-tuned and evaluated by 4 expert
                        radiation oncologists in a blinded clinical acceptability assessment on the Quannotate platform.
                    </p> */}
                    <p className="text-gray-700 text-lg md:text-sm mb-2">
                        In this study, we present a complete framework for development and testing of models for auto-segmenting OAR in HNC
                        patients. As shown in Figure 1, SCARF consists of five steps:
                    </p>
                    <ol className="w-full items-left text-gray-700 text-lg md:text-sm list-decimal mb-4 ml-8">
                        {overviewData.map(item => (
                            <li className="my-1">
                                <p>
                                    <span className="font-bold">{item.title}: </span>
                                    {item.content}
                                </p>
                            </li>
                        ))}
                    </ol>
                    <span className="text-gray-700 text-lg font-bold mb-2">Use Case: Training and Benchmarking of New Model</span>
                    <p className="text-gray-700 text-lg md:text-sm">
                        To showcase how the performance of a new model architecture can be comprehensively assessed using our SCARF
                        framework we trained an additional model to be compared to our best-performing fine tuned model. Specifically, we
                        trained and benchmark the recent and popular nnUNet model with additional assessments for generalizability and
                        clinical acceptability.
                    </p>
                </div>
            </div>

            <div id="abstract" className="px-32 lg:px-16 md:px-6 py-10">
                <h1 className="font-bold text-5xl md:text-3xl text-gray-800 mb-8">Abstract</h1>
                <div className="flex flex-col items-center">
                    <div className="w-full items-left text-gray-700 text-lg md:text-sm">
                        {abstractData.map(item => (
                            <div className="mb-4">
                                <p>
                                    <span className="font-bold">{item.title}: </span>
                                    {item.content}
                                </p>
                            </div>
                        ))}
                        <div className="text-lg md:text-sm">
                            <div className="font-bold mb-2">Highlights: </div>
                            <ul className="list-disc pl-8">
                                <li>
                                    <p>
                                        SCARF is a research, development and clinical assessment framework for auto-segmentation of
                                        organs-at-risk in head and neck cancer.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        SCARF facilitates benchmarking and expert assessment of AI-driven auto-segmentation tools,
                                        addressing the need for transparency and reproducibility in this domain.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        New models can be benchmarked against 11 pre-trained open-source deep learning models, while
                                        estimating clinical acceptability using a regularized logistic regression model.
                                    </p>
                                </li>
                                <li>
                                    <p>The SCARF framework code base is openly-available for OAR auto-segmentation benchmarking.</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div id="methods" className="px-32 lg:px-16 md:px-6 py-10">
                <h1 className="font-bold text-5xl md:text-3xl text-gray-800 mb-8">Methods</h1>
                <div className="flex flex-col items-center text-gray-700">
                    <p className="mb-6 text-lg md:text-sm">
                        <span className="font-bold">Reproducibility: </span>
                        The first half of our framework focuses on the reproducible development of code and software used during dataset
                        curation, model selection and model training stages.
                        <br />
                        <br />
                        <span className="font-bold">Acceptability: </span>
                        In the second half, emphasis is placed on assuring that the trained model is generalizable and clinically
                        acceptable. Generalizability is assessed by testing the model on external datasets to evaluate its performance
                        consistency and robustness. Clinical acceptability is then determined using tools like QUANNOTATE, where
                        segmentation results were rated by expert observers.
                    </p>
                    <div className="px-20">
                        <Image
                            src="/images/section-images/figure1.jpg"
                            className="border-2 border-gray-300 rounded-lg"
                            alt="Methods"
                            preview
                        />
                        {/* <p className="text-xs md:text-xxs">
                            <span className="font-bold">
                                Figure 1: SCARF Overview: auto-Segmentation Clinical Acceptability & Reproducibility Framework.
                            </span>{' '}
                            Reproducibility: The first half of the framework emphasizes reproducible development of code and software used
                            during dataset curation and model training stages. Acceptability: In the second portion of the framework,
                            emphasis is placed on development of an acceptability standard that uses quantitative performance evaluation,
                            clinical acceptability assessment, and generalizability assessment.
                        </p> */}
                    </div>
                </div>
            </div>

            <div id="results" className="px-32 lg:px-16 md:px-6 pt-16 md:pt-10">
                <h1 className="font-bold text-5xl md:text-3xl text-gray-800 mb-8">Results</h1>
                <div className="flex flex-col items-center text-gray-700">
                    <div className="mb-8 items-center">
                        <p className="text-lg md:text-sm">
                            <span className="font-bold">Performance Assessment: </span>
                            Model(s) were each trained with 523 patient scans for 3 days on 4xTesla P100 GPU(s) or until convergence using
                            PyTorch-lightning. Models were assessed using classical metrics (DICE and 95HD) for all OAR(s) when applied to a
                            hold out set of 59 scans. The figure below highlights the following: (A) A heatmap displaying the ranking
                            performance of each model for all OARs, where WOLNET was the only model to rank in the top 5 for all OARs and
                            the top 3 for 16 of the 19 OARs. (B) DICE values for the Fine-Tuned WOLNET model across each OAR category, and
                            (C) 95HD values for the Fine-Tuned WOLNET model across each OAR category. Based on these results, WOLNET, a
                            simple 3D UNet architecture, was chosen for retraining and further evaluation in clinical and generalizability
                            assessments.
                        </p>
                    </div>

                    <div className="px-20 mb-20">
                        <Image
                            src="/images/section-images/figure2.jpg"
                            className="h-auto border-2 border-gray-300 rounded-lg"
                            alt="performance"
                            preview
                        />
                        {/* <p className="text-xs md:text-xxs">
                            <span className="font-bold">
                                Figure 2: Selection and results of the best performing model: Fine-Tuned WOLNET.
                            </span>{' '}
                            A) a heatmap showing the associated ranking performance of a model for each OAR. WOLNET was the only model to
                            rank in the top 5 for all OARs, and top 3 for 16 of the 19 OARs. B) DICE values for our Fine-Tuned WOLNET model
                            for each OAR category, and C) 95HD values for our Fine-Tuned WOLNET model for each OAR category.
                        </p> */}
                    </div>
                    <div className="mb-8">
                        <p className="text-lg md:text-sm">
                            <span className="font-bold">Clinical Assessment: </span>
                            The following figure shows heatmaps of mean acceptability rating (MAR) counts for each organ-at-risk (OAR): (A)
                            Ground-Truth (GT) contours and (B) AI-generated contours. Each row represents an OAR, and each column
                            corresponds to a specific MAR value (1 to 5, with 5 being clinically ideal). Lighter boxes indicate a higher
                            frequency of contours receiving that MAR score. The AI-generated contours were less clinically acceptable
                            compared to manual GT contours (A). The GT contours achieved a significantly higher overall mean MAR of 3.75 (±
                            0.77) compared to 3.23 (± 0.86) for AI contours across all OARs (p &lt; 0.01).
                        </p>
                    </div>

                    <div className="px-20 flex flex-col justify-center items-center mb-4">
                        <div className="w-full md:w-full mb-20">
                            <Image
                                src="/images/section-images/results-2.png"
                                className="w-full h-auto border-2 border-gray-300 rounded-lg"
                                alt="clinical"
                                preview
                            />
                            {/* <p className="text-xs md:text-xxs">
                                <span className="font-bold">
                                    Figure 3: Results Of Fine-Tuned WOLNET Clinical Evaluation Recording Using Quannotate QA Tool.
                                </span>{' '}
                                Results of the acceptability test by representing mean acceptability rating (MAR) counts for each OAR in a
                                heat-map for (A) Ground-Truth contours (GT) and (B) AI-generate contours (AI). The higher the value of a box
                                the more contours of that given OAR (row) had any given MAR value (column) and the lighter that box will be.
                                Notice a shift to the left when examining the heat-map of mean acceptability ratings for deep learning
                                contours examined for each OAR indicating a greater degree of clinical acceptance for manual contours as
                                depicted by figure 4D. GT contours received a significantly higher mean rating of 3.75 than AI contours
                                which were rated 3.23 when all OARs were considered (3.75 ± 0.77 vs. 3.23 ± 0.86, p &lt; 0.01).
                            </p> */}
                        </div>
                    </div>
                    <div className="mb-8">
                        <p className="text-lg md:text-sm">
                            <span className="font-bold">Generalizability Assessment: </span>
                            The following figure compares Dice similarity coefficient (DICE) scores for the Fine-Tuned WOLNET (A) and our
                            use case model, nnUNet (B) across eight external datasets. Each OAR’s DICE scores are shown, with greater
                            variation for certain structures, such as the acoustic, due to differences in contouring protocols between
                            centers. While both models performed similarly overall, nnUNet showed better performance on the external
                            datasets. However, notable outliers were observed, such as the acoustic structures in the Segrap dataset, where
                            the Fine-Tuned WOLNET had a significant performance drop compared to nnUNet.
                        </p>
                    </div>

                    <div className="px-20 mb-12 flex flex-col justify-center items-center">
                        <div className="w-3/4 md:w-full">
                            <Image
                                src="/images/section-images/figure3.jpg"
                                className="w-full h-auto border-2 border-gray-300 rounded-lg"
                                alt="generalizability"
                                preview
                            />
                            {/* <p className="text-xs md:text-xxs">
                                <span className="font-bold">Figure 4: Generalizability Assessment of Fine-Tuned WOLNET and nnUNet.</span>{' '}
                                Box plots to show variation of DICE of the Fine-Tuned WOLNET and nnUNet across eight external datasets. A)
                                DICE for Fine-Tuned WOLNET, and B) DICE for nnUNet.
                            </p> */}
                        </div>
                    </div>
                </div>
            </div>

            <div id="data" className="px-32 lg:px-16 md:px-6 pt-16 md:pt-10 w-full mb-10">
                <h1 className="font-bold text-5xl md:text-3xl text-gray-800 mb-8">Data Table</h1>
                <div className="w-full overflow-x-auto">
                    <p className="text-sm md:text-xs break-words font-bold text-red-800 mb-3 w-full">
                        *Missing data is represented in gray*
                    </p>
                    <DataTableComponent className="w-full" />
                </div>
            </div>

            <div id="citation" className="px-32 sm:px-4 py-10 mb-40 sm:mb-10">
                <h1 className="font-bold text-5xl md:text-3xl text-gray-800 mb-8">Citation</h1>
                <div className="flex flex-col items-center">
                    <div className="w-full sm:w-1/2 md:w-3/4 lg:w-5/6 xl:w-6/6 overflow-x-auto px-2 py-2 bg-orange-100/50 rounded-lg border-2 border-orange-100/90 text-wrap">
                        <p className="font-mono sm:font-base md:text-xs">
                            {
                                'author = {Marsilla, Joseph and Won Kim, Jun and Kim, Sejin and Tkachuck, Denis and Rey-McIntyre, Katrina and Patel, Tirth and Tadic, Tony and Liu, Fei-Fei and Bratman, Scott and Hope, Andrew and Haibe-Kains, Benjamin},'
                            }
                            <br />
                            {
                                'title = {Evaluating clinical acceptability of organ-at-risk segmentation In head & neck cancer using a compendium of open-source 3D convolutional neural networks},'
                            }
                            <br />
                            {'elocation-id = {2022.01.15.22269276},'}
                            <br />
                            {'year = {2022},'}
                            <br />
                            {'doi = {10.1101/2022.01.15.22269276},'}
                            <br />
                            {'publisher = {Cold Spring Harbor Laboratory Press},'}
                            <br />
                            {'URL = {https://www.medrxiv.org/content/early/2022/01/25/2022.01.15.22269276},'}
                            <br />
                            {'eprint = {https://www.medrxiv.org/content/early/2022/01/25/2022.01.15.22269276.full.pdf},'}
                            <br />
                            {'journal = {medRxiv}'}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default App;
